import React from 'react';
import './stylesheets/_index.scss';
import { PayloadItem } from 'shared/lib/api/listViews';
import Wrapper from 'components/Strapi/Wrapper';
import { Button } from 'components/Buttons';

interface FiveColumnTableProps {
  data: PayloadItem[];
  columnOverrides?: string;
  urlAttribute?: string;
  recordsPerPage?: string;
  bgColor?: {
    selectedColor: string;
  };
  sortColumnIndex?: number;
}

interface EntryDetailsProps {
  item: PayloadItem;
  column: { key: string; displayName: string };
  cellIndex: number;
  urlAttribute?: string;
}

const getUrl = (item: PayloadItem, urlAttribute?: string) => {
  if (urlAttribute && Object.prototype.hasOwnProperty.call(item, urlAttribute)) {
    return item[urlAttribute]; // Return the value of the key
  }
  return '';
};

const MobileEntryDetails: React.FC<EntryDetailsProps> = ({ item, column, cellIndex, urlAttribute }) => {
  return (
    <div className='volunteer--mobile__details-container'>
      <div className='volunteer--mobile__details'>
        <p className='volunteer--mobile__label'>{column.displayName.replace(/_/g, ' ')}:</p>
        {cellIndex === 4 ? (
          <div className='volunteer--mobile__details'>
            <Button openInNewTab={true} text="Learn More" link={getUrl(item, urlAttribute)} customClassName='button--five-column-table' />
          </div>
        ) : (
          <p className='volunteer--mobile__content'>{item[column.key]}</p>
        )}
      </div>
    </div>
  );
};

const DesktopRowDetails: React.FC<EntryDetailsProps> = ({ item, column, cellIndex, urlAttribute }) => {
  return (
    <td>
      {cellIndex === 4 ? (
        <Button openInNewTab={true} text="Learn More" link={getUrl(item, urlAttribute)} customClassName='button--five-column-table' />
      ) : (
        item[column.key]
      )}
    </td>
  );
};

const sortData = (data: PayloadItem[], columnIndex: number | undefined): PayloadItem[] => {
  if (columnIndex === undefined || data.length === 0) return data;

  const columnKey = Object.keys(data[0])[columnIndex];

  return [...data].sort((a, b) => {
    const aValue = a[columnKey];
    const bValue = b[columnKey];

    if (aValue === undefined || bValue === undefined) return 0;

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }

    return 0;
  });
};

const adjustColumnOrder = (columnHeadings: { key: string; displayName: string }[], sortColumnIndex: number | undefined) => {
  const adjustedColumnHeadings = [...columnHeadings.slice(0, 4)];
  if (sortColumnIndex !== undefined && sortColumnIndex < 4) {
    adjustedColumnHeadings.unshift(adjustedColumnHeadings.splice(sortColumnIndex, 1)[0]);
  }
  adjustedColumnHeadings.push(columnHeadings[4]); // Ensure the fifth column stays in its position
  return adjustedColumnHeadings;
};

export const FiveColumnTable: React.FC<FiveColumnTableProps> = ({ data, columnOverrides, urlAttribute, bgColor, recordsPerPage, sortColumnIndex }) => {
  const sortedData = sortData(data, sortColumnIndex);
  const itemsPerPage = Number(recordsPerPage) || 5;
  const currentItems = sortedData.slice(0, itemsPerPage);

  const overrides = columnOverrides ? columnOverrides.split(',') : [];
  const columnHeadings = data.length > 0
    ? Object.keys(data[0]).slice(0, 5).map((key, index) => ({
      key,
      displayName: overrides[index] || key, // Use the override as displayName if available
    }))
    : [];

  const adjustedColumnHeadings = adjustColumnOrder(columnHeadings, sortColumnIndex);

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className='volunteer'>
        <div className='volunteer--mobile'>
          {currentItems.map((item, rowIndex) => (
            <div key={rowIndex} className="volunteer--mobile__entry">
              {adjustedColumnHeadings.map((column, cellIndex) => (
                <MobileEntryDetails
                  key={cellIndex}
                  item={item}
                  column={column}
                  cellIndex={cellIndex}
                  urlAttribute={urlAttribute}
                />
              ))}
            </div>
          ))}
        </div>
        <div className='volunteer--desktop'>
          <table>
            <thead>
              <tr>
                {adjustedColumnHeadings.map((column, index) => (
                  <th key={index}>{column.displayName.replace(/_/g, ' ')}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {adjustedColumnHeadings.map((column, cellIndex) => (
                    <DesktopRowDetails
                      key={cellIndex}
                      item={item}
                      column={column}
                      cellIndex={cellIndex}
                      urlAttribute={urlAttribute}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};