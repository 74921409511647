import React, { useState, useEffect } from 'react';
import {
  fetchDataWithQueryParams,
  PayloadItem,
} from 'shared/lib/api/listViews';
import { Calendar, EventExtras, ColorMap } from 'components/Calendar/Calendar';
import { SearchInput, DropDown } from 'components/Inputs';
import { openInNewWindow, toAbsoluteUrl } from 'shared/helpers/navHelpers';


interface ListViewCalendarProps {
  objectType: string;
  listViewID: string;
  filterAttribute: string;
  titleAttribute: string;
  filterName: string;
  startDTAttribute: string;
  endDTAttribute: string;
  urlAttribute?: string;
  colorMapAttribute: string;
  colorMap: ColorMap[]
}

const ListViewCalendar: React.FC<ListViewCalendarProps> = ({
  objectType,
  listViewID,
  ...props
}) => {
  const [records, setRecords] = useState<PayloadItem[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filterRecords, setFilteredRecords] = useState<PayloadItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await fetchDataWithQueryParams('list_view_records', {
          'object_type': objectType,
          'listview_id': listViewID,
        });
        
        setRecords(results);
        setFilteredRecords(results);
      } catch (error) {
        setError('Failed to fetch data');
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [objectType, listViewID]);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!records) return <></>;

  const handleFilterChange = (selectedFilters: string[]) => {
    if (selectedFilters.length === 0) {
      setFilteredRecords(records);
    } else {
      setFilteredRecords(
        records.filter((record) =>
          selectedFilters.includes(record[props.filterAttribute])
        )
      );
    }
  };

  const handleEventClick = (event: EventExtras) => {
    if (props.urlAttribute && event.url) {
        openInNewWindow(toAbsoluteUrl(event.url))
    }
  }
  

  return (  
    <>
      <SearchInput name="Calendar" />
      <DropDown
        dropDownName="Filter"
        options={getFilters(records, props.filterAttribute)}
        onSelectionChange={handleFilterChange}
      />
      <Calendar
        payload={filterRecords}
        titleAttribute={props.titleAttribute}
        startDTAttribute={props.startDTAttribute}
        endDTAttribute={props.endDTAttribute}
        urlAttribute={props.urlAttribute}
        colorMapAttribute={props.colorMapAttribute}
        colorMap={props.colorMap}
        onClick={handleEventClick}
      ></Calendar>
    </>
  );
};

const getFilters = (
  records: PayloadItem[],
  filterAttribute: string
): string[] =>
  Array.from(new Set(records.map((record) => record[filterAttribute])));

export default ListViewCalendar;
