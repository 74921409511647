import { useEffect } from "react";
import WebFont from "webfontloader";
import { BrowserRouter as Router } from 'react-router-dom';
import AllRoutes from "shared/Routes/Routes";

import { Header } from 'components/Header';
import { Footer } from 'components/Footer/Footer';
import 'shared/assets/stylesheets/core.scss';

/* Font Awesome */
import { library, IconPack } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far as IconPack, fab as IconPack);

export const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:300,400,500,600,700', 'sans-serif', 'Bitter:300,400,500,600,700', "Open+Sans:300,400,500,600,700", 'serif']
      }
    });
  }, [])

  return (
    <Router>
      <Header />
      <AllRoutes />
      <Footer />
    </Router>
  )
}
