import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";
import { TextIntroTwoColumn, IntroH1, H1TextIntroOneColumn, IntroThreeColumn } from "components/CopyBlocks/Intro";
import { StandardHero } from "components/Hero";
import { ImageBlockNew } from "components/ImageBlocks";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { NewsCardCarousel } from "components/NewsCardCarousel/NewsCardCarousel";
import { InfographicStandard } from "components/InfographicStandard/InfographicStandard";
import { FilteredList, SkeletonLoader } from "components/FilteredList/FilteredList";
import ListView from "components/ListViews/ListView";

import './_index.scss';
import { FourRowIcon } from "components/CopyBlocks/ColumnList";

const NewsTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['In The News']),
    slug: slug
  });

  const componentsData = pageData[0]?.components
  if (!componentsData) return <SkeletonLoader count={10} />

  return (
    <div>
      <StrapiDynamicZone
        components={{
          FilteredList: FilteredList,
          H1TextIntroOneColumn: H1TextIntroOneColumn,
          StandardHero: StandardHero,
          ImageBlockNew: ImageBlockNew,
          Video: VideoPlayerWithWrapper,
          NewsCardCarousel: NewsCardCarousel,
          TextIntroTwoColumn: TextIntroTwoColumn,
          IntroThreeColumn: IntroThreeColumn,
          InfographicStandard: InfographicStandard,
          IntroH1: IntroH1,
          ListView: ListView,
          FourRowIcon: FourRowIcon,
        }}
        data={componentsData}
      />
    </div>
  )
}

export default NewsTemplate;