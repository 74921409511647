import { useState } from 'react';
import megaMenuData from '../data/mega-menu.json';

import { Button } from 'components/Buttons';
import { NavItem } from './NavItem';
import { NavRow } from './NavRow';
import '../stylesheets/mega-menu.scss';

type MegaMenuItemProps = {
  title?: string;
  desc?: string;
  button?: any;
  menu?: any;
  lists?: any;
  color?: string;
}

export const MegaMenu = ({ showDesktopSearch }: { showDesktopSearch: boolean }) => {
  const { items } = megaMenuData;
  const [currentMenu, setCurrentMenu] = useState<MegaMenuItemProps | null>(null);

  if (!items) return <></>;

  return (
    <div className={`menu menu--mega ${showDesktopSearch ? 'menu--megaWithSearch' : ''}`} onMouseLeave={() => setCurrentMenu(null)}>
      <NavRow style="bare">
        {items.map((item, i) =>
          <NavItem
            key={i}
            contents={item.button.name}
            link={item.button.href}
            onHover={() => setCurrentMenu(item)}
          />
        )}
      </NavRow>

      {currentMenu && <><div className={`menu__left ${currentMenu.color}`}>
        <h3 className='title'>{currentMenu.menu?.title}</h3>
        <Button
          text={currentMenu.menu?.button.text}
          link={currentMenu.menu?.button}
          customClassName='menu__leftButton'
        />
        <div className='menu__leftSocials'>
          <ul>
            <li><Button icon='instagram' iconOnly={true} iconPrefix='fab' link='https://www.instagram.com/cfrichmondva/' /></li>
            <li><Button icon='facebook' iconOnly={true} iconPrefix='fab' link='https://www.facebook.com/cfrichmondva' /></li>
            <li><Button icon='linkedin' iconOnly={true} iconPrefix='fab' link='https://www.linkedin.com/company/the-community-foundation/' /></li>
            <li><Button icon='vimeo' iconOnly={true} iconPrefix='fab' link='https://vimeo.com/cfrichmondva' /></li>
          </ul>
        </div>
      </div>
        <div className='menu__right'>
          {currentMenu.menu?.lists.length === 0 &&
            <div className='no-lists'>COMING SOON!</div>
          }
          {currentMenu.menu?.lists.map((list: any, i: number) => {
            return <div key={i} className='menu__list-item'>
              <h4>
                <a href={list.href}>{list.heading}</a>
              </h4>
              <ul>
                {list.links.map((link: any, linkIterator: number) =>
                  <li key={linkIterator}>
                    <a href={link.href} target={link.target} rel={link.rel}>{link.name}</a>
                  </li>
                )}
              </ul>
            </div>
          })}
        </div></>}
    </div>
  )
}
