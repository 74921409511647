import { useState, useEffect, useRef } from 'react';

import algoliasearch from 'algoliasearch';
import parse from 'html-react-parser';

import { SearchInput } from '../Inputs';
import { ColorDivider } from 'components/Dividers/Dividers';

import StrapiLink from 'components/Strapi/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { generatePageNumbers, SkeletonLoader, displayResults, Page } from './FilteredList';
import { removePageId, removeTags } from 'shared/helpers/textHelpers';

interface AlgoliaFilteredListProps {
  itemsPerPage?: number;
}

export const AlgoliaFilteredList = ({ itemsPerPage = 10 }: AlgoliaFilteredListProps) => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0)
  const [pageData, setPageData] = useState<any[] | null>(null);
  const [allResults, setAllResults] = useState([])
  const [emptySearch, setEmptySearch] = useState(false)
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const debounceTime = 500; //ms
  const allowedKeys = ["h1", "h2", "h3", "p", "title", "subtitle", "description", "desc"];
  const shownMatchesPerPage = 2;

  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_PROVIDER_APPLICATION_ID || '8JNM7WF6H3', process.env.REACT_APP_ALGOLIA_PROVIDER_SEARCH_API_KEY || '21795be240aa402a5bdcf9d6c7aca474');

  const indexes = [
    'production_api::discover-page.discover-page',
    'production_api::engage-page.engage-page',
    'production_api::give-page.give-page',
    'production_api::receive-page.receive-page',
    'production_api::news.news',
    'production_api::other-page.other-page',
    'production_api::special-event.special-event',
    'production_api::video-page.video-page',
    'production_api::blog.blog'
  ]

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');
    if (query) {
      setSearchInput(query);
    }
  }, []);

  const fetchResults = async () => {
    if (searchInput.length === 0) {
      resetSearch();
      return;
    }
    setIsLoading(true);

    try {
      const queries = indexes.map(index => ({
        indexName: index,
        query: searchInput,
        params: {
          hitsPerPage: 1000 // Adjust this to a reasonably high number
        }
      }));

      const results = await client.multipleQueries(queries);
      let allHits: any = [];

      results.results.forEach(result => {
        if ('hits' in result) {
          allHits = allHits.concat(result.hits);

          // Count the number of highlighted matches for each result
          allHits.forEach((hit: any) => {
            let highlightCount = 0;
            const title = hit._highlightResult?.title

            // Manual matching outside of the page content, specifically for the title field
            if (title?.matchedWords.length > 0) highlightCount += title.matchedWords.length

            hit?._highlightResult?.content && hit._highlightResult.content[0] &&
              Object.values(hit._highlightResult.content).forEach((highlight: any) => {
                Object.keys(highlight).forEach((key) => {
                  if (highlight[key]?.matchedWords?.length > 0 && allowedKeys.includes(key.toLowerCase())) {
                    highlightCount += highlight[key].matchedWords.length;
                  }
                });
              });
            hit.highlightCount = highlightCount; // Add a new property to store the count
          });
          allHits = allHits.filter((a: any) => a.highlightCount > 0);
        }
      });

      // filters out results with no matches
      allHits.sort((a: any, b: any) => removePageId(a.title).localeCompare(removePageId(b.title))); //sort a-z

      // Save all results to state
      setAllResults(allHits);
      setTotalCount(allHits.length);
      setTotalPages(Math.ceil(allHits.length / itemsPerPage));

      // Set initial filtered results for the first page
      setPageData(allHits.slice(0, itemsPerPage));
      setEmptySearch(false);
    } catch (error) {
      console.error('Algolia search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetSearch = () => {
    setIsLoading(false);
    setCurrentPage(1);
    setPageData([]);
    setAllResults([]);
    setTotalCount(0);
    setTotalPages(0);
    setEmptySearch(true);
  };

  const debouncedFetchResults = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      if (searchInput.length === 0) {
        resetSearch();
        return;
      }
      fetchResults();
    }, debounceTime);
  };

  useEffect(() => {
    debouncedFetchResults();
    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchInput]);


  useEffect(() => {
    if (allResults.length > 0) {
      handlePageChange(currentPage);
    }
  }, [currentPage]);

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handlePageChange = (page: number | Page) => {
    if (typeof page === 'number') {
      const offset = (page - 1) * itemsPerPage;
      const paginatedHits = allResults.slice(offset, offset + itemsPerPage);
      setPageData(paginatedHits);
      setCurrentPage(page);
    }
  };

  const renderHighlightedResults = (item: any) => {
    const matches = new Set(); // Using a Set to track unique matches
    const highlights: any = [];

    // Collect unique highlights
    // Filter out un-wanted keys
    let count = 0;
    item?._highlightResult?.content && item._highlightResult.content[0] &&
      Object.values(item._highlightResult.content).forEach((highlight: any) => {
        Object.keys(highlight).forEach((highlightKey: any) => {
          const highlightValue = highlight[highlightKey]
          const matchedWord = highlightValue.value;

          if (highlightValue?.matchedWords &&
            highlightValue.matchedWords[0] &&
            !matches.has(matchedWord) &&
            allowedKeys.includes(highlightKey.toLowerCase()) && count <= (shownMatchesPerPage - 1)
          ) {
            matches.add(matchedWord);
            highlights.push(highlightValue);
            count++;
          }
        });
      });

    // Render the unique highlights
    return Object.keys(highlights).map((key, idx) => {
      return (
        <span className="highlighted-content__value" key={idx}>
          {parse(highlights[key].value)}
        </span>
      );
    });
  };

  const renderHighlightedTitle = (title: any) => {
    if (!title || !title.value) return '';
    if (title.matchedWords[0]) return removePageId(removeTags(title.value));
    return removePageId(title.value);
  };

  return (
    <div className="algolia-algolia-filtered-list algolia-filtered-list" style={{ minHeight: (itemsPerPage * 100) + 'px' }}>
      <div className='algolia-filtered-list--inputs'>
        <div className='container container--large'>
          <div>
            <SearchInput
              name='keyword'
              placeholder='Search'
              label='Keyword'
              type='input--keyword-search'
              onChange={handleKeywordChange}
              stateValue={searchInput}
            />
            {displayResults({ isMobile: true, totalCount, currentPage, itemsPerPage })}
          </div>

          {displayResults({ isMobile: false, totalCount, currentPage, itemsPerPage })}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader count={itemsPerPage} />
      ) : emptySearch ?
        <div className="algolia-filtered-list__noResults">
          <div className="container container--large">
            <h3>Search Community Foundation Of Richmond</h3>
          </div>
        </div>
        : pageData && pageData.length > 0 ? pageData.map((item, index) => (
          <div key={index} className="algolia-filtered-list__item">
            <StrapiLink
              linkData={!item?.link ? { href: item?.slug } : item.link}
              className="container container--large"
              programmaticOpen={true}
            >
              <div className="algolia-filtered-list__itemContent">
                <span className="algolia-filtered-list__itemContentTitle">
                  {renderHighlightedTitle(item._highlightResult.title)}
                </span>
                {item._highlightResult && item._highlightResult.content && (
                  <div className="highlighted-content">
                    {renderHighlightedResults(item)}
                  </div>
                )}
              </div>
            </StrapiLink>
          </div>
        )) : (
          <div className="algolia-filtered-list__noResults">
            <div className="container container--large">
              <h3>No results found</h3>
              <p>Try adjusting your search filters</p>
            </div>
          </div>
        )}

      {pageData && pageData.length > 0 && <>
        <ColorDivider size='lg' />

        <div className='algolia-filtered-list__pagination'>
          <div className='container container--large'>
            <button
              className='algolia-filtered-list__paginationArrowLeft'
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {generatePageNumbers(currentPage, totalPages).map((page, index) => {
              let displayText;
              let buttonClass = 'algolia-filtered-list__paginationBtn';

              if (typeof page === 'object' && page.type === 'ellipsis') {
                displayText = '...';
                buttonClass += ' algolia-filtered-list__paginationEllipsis';
              } else {
                displayText = page.toString();
                buttonClass += currentPage === page ? ' active' : '';
              }

              return (
                <button
                  key={index}
                  className={buttonClass}
                  onClick={() => handlePageChange(page)}
                >
                  {displayText}
                </button>
              );
            })}
            <button
              className='algolia-filtered-list__paginationArrowRight'
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </>
      }
    </div>
  );
}
