import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";

import { StandardHero } from "components/Hero";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { FilteredList, SkeletonLoader } from "components/FilteredList/FilteredList";
import { ResourceCard } from "components/ResourceCard/ResourceCard";

import './_index.scss';
import { FourRowIcon } from "components/CopyBlocks/ColumnList";

const VideoTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Video Pages']),
    slug: slug
  });
  console.log(pageData);

  const componentsData = pageData[0]?.components
  if (!componentsData) return <SkeletonLoader count={10} />

  return (
    <div>
      <StrapiDynamicZone
        components={{
          Video: VideoPlayerWithWrapper,
          StandardHero: StandardHero,
          FilteredList: FilteredList,
          ResourceCard: ResourceCard,
          FourRowIcon: FourRowIcon,
        }}
        data={componentsData} // Pass only the components array
      />
    </div>
  )
}

export default VideoTemplate;