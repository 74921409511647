
import React, { useState } from "react";
import { DotGroup, CarouselProvider, Slide, Slider } from "pure-react-carousel";

import { IntroH1, IntroProps } from "components/CopyBlocks/Intro";
import { Button } from "components/Buttons";
import StrapiImage from 'components/Strapi/Image';
import StrapiButton from 'components/Strapi/Button';

import { Image as ImageProps } from 'shared/interfaces/Image';
import { Button as ButtonProps } from 'shared/interfaces/Button';

import Wrapper from "components/Strapi/Wrapper";

import "./stylesheets/image-scroller.scss";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isMobile } from "react-device-detect";

type ImageScrollerCarouselProps = {
  bgColor?: {
    selectedColor: string
  },
  introH1Component: IntroProps;
  tabs: {
    image: ImageProps;
    cta: ButtonProps;
  }[]
}

interface CustomDotGroupProps {
  setCurrentIndex: (index: number) => void;
  totalSlides: number;
  currentIndex: number;
}

const CustomDotGroup = ({ setCurrentIndex, totalSlides, currentIndex }: CustomDotGroupProps): React.ReactElement => {
  return (
    <div className="image-scroller__custom-dots">
      {Array.from({ length: totalSlides }).map((_, i) => {
        const isActive = i === currentIndex;
        return (
          <button
            key={i}
            onClick={() => setCurrentIndex(i)}
            className={`image-scroller__custom-dot ${isActive ? 'image-scroller__custom-dot--selected' : ''}`}
          >
          </button>
        );
      })}
    </div>
  );
};

export const ImageScrollerCarousel = (props: ImageScrollerCarouselProps): React.ReactElement => {
  const { tabs, bgColor, introH1Component: { title, desc, cta } } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const nextSlide = () => {
    if (selectedTab < tabs.length - 1) {
      setSelectedTab(selectedTab + 1);
    }
  };

  const previousSlide = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="container container--large">
        <div className={`image-scroller`}>
          <IntroH1
            title={title}
            desc={desc}
            cta={cta}
          />
          <div className="image-scroller__desktop">
            <CarouselProvider
              naturalSlideWidth={0}
              naturalSlideHeight={0}
              totalSlides={tabs.length}
              currentSlide={selectedTab}
              isIntrinsicHeight={true}
              visibleSlides={3.5}
              infinite={true}
              className="image-scroller__desktop__carousel"
            >
              <Slider classNameTray={"image-scroller__desktop__slider-tray"}>
                {tabs?.map(({ image, cta }, index) => {
                  return (
                    <Slide index={index} key={index} className="image-scroller__desktop__inner-slide">
                      <div className="image-scroller__desktop__content">
                        <StrapiImage data={image} />
                        {cta &&
                          <StrapiButton
                            data={cta}
                            customClassName={`button--image-scroller-carousel image-scroller__desktop__content__button-overlay`}
                          />
                        }
                      </div>
                    </Slide>
                  );
                })}
              </Slider>
              <div className="image-scroller__slide-indicator">
                <div>
                  <Button
                    onClick={() => previousSlide()}
                    customClassName="button--h1-image-carousel-arrow"
                    icon={"arrow-left"}
                  />
                </div>
                <CustomDotGroup
                  setCurrentIndex={setSelectedTab}
                  totalSlides={tabs.length}
                  currentIndex={selectedTab}
                />
                <Button
                  onClick={() => nextSlide()}
                  customClassName="button--h1-image-carousel-arrow"
                  icon={"arrow-right"}
                />
              </div>
            </CarouselProvider>
          </div>
          <div className="image-scroller__mobile">
            <CarouselProvider
              naturalSlideWidth={3}
              naturalSlideHeight={3}
              totalSlides={tabs.length}
              currentSlide={selectedTab}
              isIntrinsicHeight={true}
              dragEnabled={isMobile}
              visibleSlides={1}
              infinite={true}
              className="image-scroller__mobile__carousel"
            >
              <Slider classNameTray={"image-scroller__mobile__slider-tray"}>
                {tabs?.map(({ image, cta }, index) => {
                  return (
                    <Slide index={index} key={index} className="image-scroller__mobile__inner-slide" >
                      <div className="image-scroller__mobile__content">
                        <StrapiImage data={image} />
                        {cta &&
                          <StrapiButton
                            data={cta}
                            customClassName={`button--image-scroller-carousel image-scroller__mobile__content__button-overlay`}
                          />
                        }
                      </div>
                    </Slide>
                  );
                })}
              </Slider>
              <div className="image-scroller__slide-indicator">
                <DotGroup
                  showAsSelectedForCurrentSlideOnly={true}
                  className="image-scroller__dots"
                />
              </div>
            </CarouselProvider>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}