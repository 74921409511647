import dayjs from 'dayjs';
import React from 'react';
import { Calendar as Cal, dayjsLocalizer, Event } from 'react-big-calendar';
import Wrapper from 'components/Strapi/Wrapper';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PayloadItem } from 'shared/lib/api/listViews';
import { Color } from 'shared/interfaces/Color';
import { mapColor } from 'shared/helpers/colorMapping';
import './stylesheets/calendar.scss';

export interface ColorMap {
  key: string;
  color: Color;
}

interface CalendarLegendProps {
  colorMaps: ColorMap[];
}

interface CalendarAttributes {
  titleAttribute: string;
  startDTAttribute: string;
  endDTAttribute: string;
  urlAttribute?: string;
  colorMap: ColorMap[];
  colorMapAttribute: string;
}

interface CalendarProps extends CalendarAttributes {
  payload: PayloadItem[];
  onClick?: (event: EventExtras) => void;
}

export interface EventExtras extends Event {
  url?: string;
  bgColor?: string;
}

const localizer = dayjsLocalizer(dayjs);

export const Calendar: React.FC<CalendarProps> = ({
  payload,
  titleAttribute,
  startDTAttribute,
  endDTAttribute,
  urlAttribute,
  colorMapAttribute,
  colorMap,
  onClick,
}) => {
  const events = payload;

  if (!events) {
    return <></>;
  }

  const parsedEvents: EventExtras[] = events.map((event) => {
    const bgColor = getEventBGColor(event, colorMapAttribute, colorMap);

    return {
      start: dayjs(event[`${startDTAttribute}`]).toDate(),
      end: dayjs(event[`${endDTAttribute}`]).toDate(),
      title: event[`${titleAttribute}`],
      url: urlAttribute ? event[`${urlAttribute}`] : undefined,
      bgColor: bgColor,
    };
  });

  return (
    <Wrapper>
      <Cal
        localizer={localizer}
        events={parsedEvents}
        defaultDate={new Date()}
        defaultView="month"
        style={{ height: '100vh' }}
        onSelectEvent={onClick}
        eventPropGetter={eventStyleGetter}
      />
      {colorMap && <CalendarLegend colorMaps={colorMap} />}
    </Wrapper>
  );
};

const CalendarLegend: React.FC<CalendarLegendProps> = ({ colorMaps }) => {
  return (
    <div className="legend-container">
      {colorMaps.map((colorMap, index) => (
        <div className="legend-item" key={index}>
          <div
            className={`color-box background--${mapColor(
              colorMap.color.selectedColor
            )}`}
          ></div>
          <span className="color-label">{colorMap.key}</span>
        </div>
      ))}
    </div>
  );
};

const getEventBGColor = (
  payloadItem: PayloadItem,
  colorMapAttribute: string,
  colorMap: ColorMap[]
) => {
  if (!payloadItem[colorMapAttribute]) {
    return '';
  }
  const color = colorMap.find(
    (color) => color.key === payloadItem[colorMapAttribute]
  );
  return color ? color.color.selectedColor : '';
};

const eventStyleGetter = (event: EventExtras) => {
  return {
    className: event.bgColor ? `background--${mapColor(event.bgColor)}` : '',
  };
};
